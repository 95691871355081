/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../LCFQheader";
import Tzry from "../../common/fw/Tzry";
import FwTzrySelect from "../../common/fw/fwTzrySelect";
// import MyTableInfo from "./../../../components/common/Amycommon/MyTableInfo"
import { RxUtil } from "@/assets/util.js";
import { timesFnt, format, formatHMT } from "@/assets/app.js";
export default {
  name: "lcfqGwfw",
  components: {
    LCFQheader,
    Tzry,
    FwTzrySelect
    // MyTableInfo,
  },

  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "公文发文",
      //userSelect
      single: true,
      allowSelect: true,
      showUserDialog: false,
      showUserDialog2: false,
      showUserDialog3: false,
      showUserDialog4: false,
      showDataFw: false,
      showDataffsj: false,
      minDate: new Date(2020, 0, 1),
      currentDate: new Date(),
      showListpage: false,
      showRyDelect: false,
      showWordpage: false,
      showGinfo: false,
      userID: "",
      solId: "",
      actDefId: "",
      boDefId: "",
      formKey: "",
      //输入框
      wbHtmlPro: "",
      rysList: [],
      yxryList: [],
      userid: "",
      data: {
        wh: '',
        bt: '',
        ngsj: '',
        ngr: "",
        ngr_name: '',
        zs: '',
        zs_name: '',
        cs: '',
        cs_name: '',
        dy: '',
        dy_name: '',
        fjmc: '',
        szgwnr: '',
        fwlx: '',
        fwlx_name: '',
        mj: '',
        mj_name: '',
        hj: '',
        hj_name: '',
        gkzx: '',
        gkzx_name: [],
        //
        bygkly: '',
        bygkly_name: []
      },
      gksxListkey: [],
      gksxList: [],
      bygklyListkey: [],
      bygklyList: [],
      selectDatas: [],
      meetingUi: false,
      mjUi: false,
      hjUi: false,
      meetingList: [],
      mjList: [],
      hjList: []
    };
  },
  created() {
    this.cgORxj();
    this.initBySolInstId();
    this.getUser();
  },
  methods: {
    initBySolInstId() {
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(response => {
        console.log(response.data.bpmSolution);
        this.solId = response.data.bpmSolution.solId;
        this.actDefId = response.data.bpmSolution.actDefId;
        this.boDefId = response.data.formModels[0].boDefId;
        this.formKey = response.data.formModels[0].formKey;
      });

      //http://61.177.215.68:7020/xzoa/sys/core/sysDic/listByKey.do?key=FWLB&_=1648617830874
      /**获取发文分类信息*/
      var urlFwlb = _baseUrl + "/sys/core/sysDic/listByKey.do?key=FWLB";
      this.$ajax.post(urlFwlb).then(res => {
        this.selectDatas[0] = res.data;
        console.log(this.selectDatas);
        for (var item of this.selectDatas[0]) {
          this.meetingList.push(item.name);
        }
        //另一种写法
        // for(var i in this.selectDatas[0]){
        //   this.meetingList[i] = this.selectDatas[0][i].name;
        // }
        //console.log(this.meetingList)
      });
      /**获取公开属性*/
      var urlGksx = _baseUrl + "/sys/core/sysDic/listByKey.do?key=GKSX";
      this.$ajax.post(urlGksx).then(res => {
        this.selectDatas[1] = res.data;
        //this.gksxList = res.data;//处理的只有key和name试试

        for (var i of res.data) {
          this.gksxListkey.push(i.key);
          this.gksxList.push(i.name);
          //this.gksxList.push({key:i.key, name:i.name});
        }

        console.log('公开属性name=', this.gksxList);
        console.log('公开属性key=', this.gksxListkey);
      });
      /**获取不予公开理由*/
      var urlBygkly = _baseUrl + "/sys/core/sysDic/listByKey.do?key=BYGKLY";
      this.$ajax.post(urlBygkly).then(res => {
        this.selectDatas[2] = res.data;
        //this.bygklyList = res.data;
        for (var i of res.data) {
          this.bygklyListkey.push(i.key);
          this.bygklyList.push(i.name);
          //this.gksxList.push({key:i.key, name:i.name});
        }

        console.log(this.selectDatas);
      });
      /**获取秘密程度*/
      var urlMj = _baseUrl + "/sys/core/sysDic/listByKey.do?key=MJ";
      this.$ajax.post(urlMj).then(res => {
        this.selectDatas[3] = res.data;
        console.log(this.selectDatas);
        for (var item of this.selectDatas[3]) {
          this.mjList.push(item.name);
        }
      });
      /**获取缓急程度*/
      var urlHJCD = _baseUrl + "/sys/core/sysDic/listByKey.do?key=HJCD";
      this.$ajax.post(urlHJCD).then(res => {
        this.selectDatas[4] = res.data;
        console.log(this.selectDatas);
        for (var item of this.selectDatas[4]) {
          this.hjList.push(item.name);
        }
      });
    },
    async cgORxj() {
      let solId = this.$route.params.solId;
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";
        var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId + "&instId=" + instId;
        this.$ajax.post(url).then(res => {
          this.solId = res.data.bpmSolution.solId;
          this.actDefId = res.data.bpmSolution.actDefId;
          this.boDefId = res.data.formModels[0].boDefId;
          this.formKey = res.data.formModels[0].formKey;
          this.data = res.data.formModels[0].jsonData; //这个数据中多选和选人部分要单独处理。
          // console.log(this.data);

          //选人是单选，就不用分开处理了。简单处理回填
          let ngr = [{
            id: this.data.ngr,
            text: this.data.ngr_name
          }];
          this.data.ngr = JSON.stringify(ngr);
          let zs = [{
            id: this.data.zs,
            text: this.data.zs_name
          }];
          this.data.zs = JSON.stringify(zs);
          let cs = [{
            id: this.data.cs,
            text: this.data.cs_name
          }];
          this.data.cs = JSON.stringify(cs);
          let dy = [{
            id: this.data.dy,
            text: this.data.dy_name
          }];
          this.data.dy = JSON.stringify(dy);
          //回填有问题

          let item = [];
          let namearr = this.data.gkzx_name.split(',');
          for (var i = 0; i < namearr.length; i++) {
            item[i] = namearr[i];
          }
          console.log(item);
          this.data.gkzx_name = item;
          //-----------------------------------------------------
          let item2 = [];
          let namearr2 = this.data.bygkly_name.split(',');
          for (var i = 0; i < namearr2.length; i++) {
            item2[i] = namearr2[i];
          }
          console.log(item2);
          this.data.bygkly_name = item2;
        });
      }
    },
    //获取用户信息
    getUser() {
      let ngrId = RxUtil.getCache("userID");
      let ngrName = RxUtil.getCache("userName");
      let ngr = [{
        id: ngrId,
        text: ngrName
      }];
      this.data.ngr = JSON.stringify(ngr);
    },
    //选人
    selectUserDialog(vm) {
      this.data.ngr = '';
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    //选人
    selectUserDialog2(vm) {
      this.data.zs = '';
      this.showUserDialog2 = true;
      this.$refs.sysUser2.search();
      // 设置选择用户。
      this.$refs.sysUser2.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    //选人
    selectUserDialog3(vm) {
      this.data.cs = '';
      this.showUserDialog3 = true;
      this.$refs.sysUser3.search();
      // 设置选择用户。
      this.$refs.sysUser3.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    //选人
    selectUserDialog4(vm) {
      this.data.dy = '';
      this.showUserDialog4 = true;
      this.$refs.sysUser4.search();
      // 设置选择用户。
      this.$refs.sysUser4.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    nodePushData(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      console.log(json);
      this.nodeUserVm.setValue(json);
    },
    selectUser(data) {
      this.nodePushData(data);
      this.showUserDialog = false;
    },
    selectUser2(data) {
      this.nodePushData(data);
      this.showUserDialog2 = false;
    },
    selectUser3(data) {
      this.nodePushData(data);
      this.showUserDialog3 = false;
    },
    selectUser4(data) {
      this.nodePushData(data);
      this.showUserDialog4 = false;
    },
    showWord() {
      this.showWordpage = true;
    },
    wbHtml(item) {
      this.wbHtmlPro = item;
      this.showWordpage = false;
      console.log(this.wbHtmlPro);
    },
    onConfirmFw(timestamp) {
      this.data.ngsj = format(timestamp);
      this.showDataFw = false;
    },
    onConfirmffsj(timestamp) {
      this.data.ffsj = format(timestamp);
      this.showDataffsj = false;
    },
    showList() {
      this.showListpage = true;
    },
    //选择通知人员
    tzryFun(list) {
      let ryListName = [];
      let userid = [];
      this.rysList = list;
      this.rysList.map(item => {
        ryListName.push(item.FULLNAME_);
        userid.push(item.USER_ID_);
      });
      this.data.tzry = ryListName.join(",");
      this.userid = userid.join(",");
    },
    ryList(list) {
      this.tzryFun(list);
      this.showListpage = false;
    },
    delectRy() {
      console.log(this.rysList);
      this.yxryList = this.rysList;
      this.showRyDelect = true;
    },
    delectAfter(lists) {
      this.tzryFun(lists);
      this.showRyDelect = false;
    },
    meetingFunction(value) {
      this.data.fwlx_name = value;
      this.meetingUi = false;
      this.data.fwlx = '';
      for (var i in this.meetingList) {
        if (this.data.fwlx_name == this.meetingList[i]) {
          this.data.fwlx = this.selectDatas[0][i].key;
        }
      }
      console.log(this.data.fwlx + "," + this.data.fwlx_name);
    },
    mjFunction(value) {
      this.data.mj_name = value;
      this.mjUi = false;
      this.data.mj = '';
      for (var i in this.mjList) {
        if (this.data.mj_name == this.mjList[i]) {
          this.data.mj = this.selectDatas[3][i].key;
        }
      }
      console.log(this.data.mj + "," + this.data.mj_name);
    },
    hjFunction(value) {
      this.data.hj_name = value;
      this.hjUi = false;
      this.data.hj = '';
      for (var i in this.hjList) {
        if (this.data.hj_name == this.hjList[i]) {
          this.data.hj = this.selectDatas[4][i].key;
        }
      }
      console.log(this.data.hj + "," + this.data.hj_name);
    },
    datas() {
      //console.log(this.data.gkzx_name)
      //处理数据
      let gkzx = '';
      let gkzx_name = '';
      for (var item of this.data.gkzx_name) {
        gkzx_name = gkzx_name + "," + item;
      }
      for (var i = 0; i < this.gksxList.length; i++) {
        for (var j = 0; j < this.data.gkzx_name.length; j++) {
          if (this.data.gkzx_name[j] == this.gksxList[i]) {
            gkzx = gkzx + "," + this.gksxListkey[i];
          }
        }
      }
      gkzx = gkzx.slice(1);
      gkzx_name = gkzx_name.slice(1);
      console.log(gkzx, gkzx_name);

      //console.log(this.data.bygkly_name)
      let bygkly = '';
      let bygkly_name = '';
      for (var item of this.data.bygkly_name) {
        bygkly_name = bygkly_name + "," + item;
      }
      for (var i = 0; i < this.bygklyList.length; i++) {
        for (var j = 0; j < this.data.bygkly_name.length; j++) {
          if (this.data.bygkly_name[j] == this.bygklyList[i]) {
            bygkly = bygkly + "," + this.bygklyListkey[i];
          }
        }
      }
      bygkly = bygkly.slice(1);
      bygkly_name = bygkly_name.slice(1);
      console.log(bygkly, bygkly_name);
      if (this.data.ngr == '' || this.data.zs == '' || this.data.cs == '' || this.data.dy == '') {
        return null;
      }
      //简单处理为单选
      var ngr = JSON.parse(this.data.ngr);
      var zs = JSON.parse(this.data.zs);
      var cs = JSON.parse(this.data.cs);
      var dy = JSON.parse(this.data.dy);
      console.log(ngr);
      console.log(zs);
      console.log(cs);
      console.log(dy);

      // var wjtznr = JSON.stringify(this.wbHtmlPro);

      var jsonDataObj = {
        bos: [{
          boDefId: this.boDefId,
          formKey: this.formKey,
          data: {
            ngr: ngr[0].id,
            ngr_name: ngr[0].text,
            zs: zs[0].id,
            zs_name: zs[0].text,
            cs: cs[0].id,
            cs_name: cs[0].text,
            dy: dy[0].id,
            dy_name: dy[0].text,
            ngsj: this.data.ngsj,
            wh: this.data.wh,
            bt: this.data.bt,
            szgwnr: this.data.szgwnr,
            fjmc: this.data.fjmc,
            fwlx: this.data.fwlx,
            fwlx_name: this.data.fwlx_name,
            mj: this.data.mj,
            mj_name: this.data.mj_name,
            hj: this.data.hj,
            hj_name: this.data.hj_name,
            gkzx: gkzx,
            gkzx_name: gkzx_name,
            bygkly: bygkly,
            bygkly_name: bygkly_name
          }
        }]
      };
      console.log(jsonDataObj);
      var jsonData = JSON.stringify(jsonDataObj);
      console.log(jsonData);
      var params = new FormData();
      params.append("solId", this.solId);
      params.append("bpmInstId", "");
      params.append("jsonData", jsonData);
      return params;
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.ngr) || this.iN(this.data.zs) || this.iN(this.data.cs) || this.iN(this.data.dy) || this.iN(this.data.wh) || this.iN(this.data.bt) || this.iN(this.data.ngsj) || this.iN(this.data.fwlx_name) || this.iN(this.data.mj_name) || this.iN(this.data.hj_name) || this.iN(this.data.gkzx_name) || this.iN(this.data.bygkly_name) || this.iN(this.data.fjmc) || this.iN(this.data.szgwnr)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      console.log(params);
      if (params == null) {
        this.errorToast("参数填写不全！", "1800");
        return;
      }
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败！" + data.data, "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      console.log(params);
      if (params == null) {
        this.errorToast("参数填写不全！", "1800");
        return;
      }
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败！" + data.data, "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};